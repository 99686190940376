//pinia
import { defineStore } from "pinia";

//Firebase imports
import {
  getDatabase,
  ref,
  set,
  push,
  child,
  update,
  onValue,
  increment,
  //get,
} from "firebase/database";

import mixpanel from "mixpanel-browser";
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');


const db = getDatabase();

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application


// getDataFromDB

export const useUploadStore = defineStore("upload", {
  state: () => ({ upload: null }),
  actions: {

     async getDataFromDB(userId, path) {
      console.log("started: getDataFromDB");
    
      console.log(userId);
      console.log(path);
    
      console.log("initials");
    
      return new Promise(function (resolve) {
        onValue(
          ref(db, path),
          (snapshot) => {
            const data = snapshot.val();
            console.log("data");
            console.log(data);
            resolve(data);
          },
          {
            onlyOnce: false,
          }
        );
      });
    },

     async writeDataToDB(userId, data, path) {
      console.log("write data to DB");
      console.log("userId: " + userId);
      console.log("data: " + data);
      console.log("path: " + path);
      // A post entry.
      const postData = {};
    
      for (const key in data) {
        console.log("postData[key] =");
        console.log(postData[key]);
        console.log(`${key}: ${data[key]}`);
        if (key !== "writeType") {
          postData[key] = data[key];
          console.log("postData[key] 2=");
          console.log(postData[key]);
        }
      }
    
      console.log(postData);
      let updates = {};
    
      if (data["writeType"] == "stats") {
        console.log(
          "totalUploadCount in postData increment 2 : " + postData.videoNoCourseWord
        );
        // transform incriment to postData
        const postDataIncrements = {};
        console.log(postData);
        for (const key in postData) {
          postDataIncrements[key] = increment(postData[key]);
        }
        console.log("postDataIncrements");
        console.log(postDataIncrements);
        update(ref(db, path), postDataIncrements);
      }
      if (data["writeType"] == "quota") {
        console.log(
          "totalUploadCount in postData increment 2 : " + postData.totalUploadCount
        );
        update(ref(db, path), {
          totalUploadCount: increment(1),
          weeklyUploadCount: increment(1),
          dailyUploadCount: increment(1),
        });
      }
      if (data["writeType"] == "submission") {
        mixpanel.track("submission", postData);
    
        // NEW BEGINN
        // Get a key for a new Post.
        const newPostKey = push(child(ref(db), path)).key;
    
        // Write the new post's data simultaneously in the posts list and the user's post list.
    
        updates[path + "/" + newPostKey] = postData;
        update(ref(db), updates);
      }
      if ("writeType" in data == false) {
        console.log("no writeType detected - try to write anyway!");
    
        update(ref(db, path), postData);
      }
    
      // NEW END
      //update(ref(db, path), postData);
      console.log("done writing to DB for attempt");
    
      return;
    },

    async createUploadRequest(
      userId,
      filename,
      fileType,
      fileSize,
      videoName,
      fileExtension,
      language
    ) {
      console.log("started: createUploadRequest");
      console.log("language: " + language);
    
      let date = Date.now();
    
      // Get a key for a new Post.
      const newPostKey = push(child(ref(db), "uploadRequest/" + userId)).key;
    
      // A post entry.
      const postData = {
        uid: userId,
        filename: newPostKey + fileExtension,
        fileType: fileType,
        fileSize: fileSize,
        created: date,
        language: language
      };
    
      console.log("newPostKey");
      console.log(newPostKey);
      // Write the new post's data simultaneously in the posts list and the user's post list.
      const updates = {};
      updates["uploadRequest/" + userId + "/" + newPostKey] = postData;
    
      update(ref(db), updates);
    
      console.log("accountCreated uid = " + userId);
      set(ref(db, "videos/" + newPostKey), {
        userId: userId,
        videoId: newPostKey,
        originalName: videoName + fileExtension,
        filename: newPostKey + fileExtension,
        created: date,
      });
      mixpanel.track("Video uploaded", {
        fileType: fileType,
        fileSize: fileSize,
        fileExtension: fileExtension,
      });
      mixpanel.people.increment("Videos uploaded");
      console.log("donw writing to DB");
    
      return newPostKey;
    },

    async getSignedWriteUrl(userId, uploadRequestKey) {
      console.log("started: getSignedWriteUrl");
      let writeUrl = null;
    
      console.log(userId);
      console.log(uploadRequestKey);
    
      console.log("initials");
    
      return new Promise(function (resolve) {
        onValue(
          ref(
            db,
            "/uploadRequest/" + userId + "/" + uploadRequestKey + "/writeUrl"
          ),
          (snapshot) => {
            const data = snapshot.val();
            console.log("data");
            console.log(data);
            writeUrl = data;
            if (writeUrl != null) {
              resolve(writeUrl);
              //return data;
            }
          },
          {
            onlyOnce: false,
          }
        );
      });
    },

    async getDownloadUrl(userId, uploadRequestKey) {
      console.log("started: getDownloadUrl");
      let downloadUrl = null;
    
      console.log(userId);
      console.log(uploadRequestKey);
    
      console.log("initials");
    
      return new Promise(function (resolve) {
        onValue(
          ref(
            db,
            "/uploadRequest/" + userId + "/" + uploadRequestKey + "/downloadUrl"
          ),
          (snapshot) => {
            const data = snapshot.val();
            console.log("data");
            console.log(data);
            downloadUrl = data;
            if (downloadUrl != null) {
              resolve(downloadUrl);
              //return data;
            }
          },
          {
            onlyOnce: false,
          }
        );
        console.log("now running timeout");
        setTimeout(timeoutFunction, 420000);
        function timeoutFunction() {
          console.log("rejecting now");
          resolve("error");
        }
      });
    },

    async getCourseWordCount(userId, uploadRequestKey, type) {
      console.log("started: getCourseWordCount");
      let courseWords = null;
    
      console.log(userId);
      console.log(uploadRequestKey);
    
      console.log("initials");
    
      return new Promise(function (resolve) {
        onValue(
          ref(
            db,
            "/uploadRequest/" +
              userId +
              "/" +
              uploadRequestKey +
              "/amountCourseWords"
          ),
          (snapshot) => {
            const data = snapshot.val();
            console.log("data");
            console.log(data);
            courseWords = data;
            if (courseWords != null) {
              mixpanel.track("Course word", {
                displayedForUser: true,
              });
              mixpanel.people.increment("Course words found", courseWords);
              resolve(courseWords);
              //return data;
            }
          },
          {
            onlyOnce: false,
          }
        );
        console.log("now running timeout");
        setTimeout(timeoutFunction, 420000);
        function timeoutFunction() {
          console.log("rejecting now");
          resolve("error");
        }
      });
    },
     async watchDataInDB(dbPath, type) {
      console.log("started: watchDataInDB");
      let dataToWatch = null;
      console.log(dbPath);
    
      console.log("initials");
    
      return new Promise(function (resolve) {
        onValue(
          ref(
            db,
            dbPath // e.g.: "/uploadRequest/" + userId +  "/" + uploadRequestKey + "/amountCourseWords"
          ),
          (snapshot) => {
            const data = snapshot.val();
            console.log("data");
            console.log(data);
            dataToWatch = data;
            if (dataToWatch != null) {
              if (type === "object" && dataToWatch !== null && typeof dataToWatch !== "object") {
                console.log("dataToWatch is not object");
                // transform dataToWatch from string to object
                dataToWatch = JSON.parse(dataToWatch);
                console.log("dataToWatch is now object");
                resolve(dataToWatch);
        
              }
              else{
                console.log("dataToWatch is correct type: ", type);
                resolve(dataToWatch);
            }
              //return data;
            }
          },
          {
            onlyOnce: false,
          }
        );
        console.log("now running timeout");
        setTimeout(timeoutFunction, 420000);
        function timeoutFunction() {
          console.log("rejecting now");
          resolve("error");
        }
      });
    },

    async resetUpload() {
      console.log("resetUpload running");
      this.setUpload(null);
    },
    async trackMixpanel(trackingEvent, trackingObj) {
  console.log("track event");
  mixpanel.track(trackingEvent, trackingObj);
  return;
},
    //Mutation to update the user state
    //When we call this mutation, the payload will be user object from firebase auth
    //When the user logs out, we call the mutation and the payload will be null
    setUpload(payload) {
      this.upload = payload;
      //Log out the user state
    },
  },
  persist: true,
});