//pinia
import { defineStore } from "pinia";

//Firebase imports
import { auth } from "../firebase/firebaseConfig";
import { useUploadStore } from "./upload";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInAnonymously,
  signInWithPopup,
  linkWithPopup,
} from "firebase/auth";

import {
  getDatabase,
  ref,
  set,
  push,
  child,
  update,
  onValue,
  increment,
  //get,
} from "firebase/database";

import { getAnalytics, logEvent } from "firebase/analytics";

import mixpanel from "mixpanel-browser";
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

const provider = new GoogleAuthProvider();

if (process.env.VUE_APP_COLLECT_DATA === "false") {
  console.log("disabled firebase analytics");
  // Enabling the debug mode flag is useful during implementation,
  // but it's recommended you remove it for production
  const analytics = getAnalytics();
  logEvent(analytics, "Sandbox");
  mixpanel.init("43bc1f991a4b84bc105d4ce494c027c0", { debug: true });
  mixpanel.people.set({ SignupType: "sandbox", trackingVersion: "0.0.1" });
} else {
  const analytics = getAnalytics();
  logEvent(analytics, "Init Prod");
  // Enabling the debug mode flag is useful during implementation,
  // but it's recommended you remove it for production
  mixpanel.init("43bc1f991a4b84bc105d4ce494c027c0");
  mixpanel.people.set({ SignupType: "production", trackingVersion: "0.0.1" });
}

const db = getDatabase();

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application

export const useUserStore = defineStore("user", {
  state: () => ({ user: null, language: {},langOptions: [
    { name: "English", code: "en" },
  { name: "Arabic", code: "ar" },
  // { name: "Bulgarian", code: "bg" },
  { name: "Catalan", code: "ca" },
  // { name: "Croatian", code: "hr" },
  { name: "Czech", code: "cs" },
  { name: "Danish", code: "da" },
  { name: "Dutch", code: "nl" },
  { name: "Farsi", code: "fa" },
  { name: "Finnish", code: "fi" },
  { name: "French", code: "fr" },
  { name: "German", code: "de" },
  // { name: "Greek", code: "el" },
  // { name: "Hebrew", code: "he" },
  { name: "Hindi", code: "hi" },
  { name: "Hungarian", code: "hu" },
  // { name: "Indonesian", code: "id" },
  { name: "Italian", code: "it" },
  { name: "Japanese", code: "ja" },
  // { name: "Korean", code: "ko" },
  // { name: "Latvian", code: "lv" },
  // { name: "Lithuanian", code: "lt" },
  { name: "Malay", code: "ms" },
  // { name: "Mandarin Chinese, Simplified", code: "cmn" },
  { name: "Norwegian", code: "no" },
  { name: "Polish", code: "pl" },
  { name: "Portuguese (Brazil)", code: "pt" },
  { name: "Romanian", code: "ro" },
  { name: "Russian", code: "ru" },
  // { name: "Slovak", code: "sk" },
  // { name: "Slovenian", code: "sl" },
  { name: "Spanish", code: "es" },
  { name: "Swedish", code: "sv" },
  { name: "Tamil", code: "ta" },
  { name: "Telegu", code: "te" },
  { name: "Turkish", code: "tr" }
] }),
  actions: {

    async anonymSignup() {
      const user = auth.currentUser;
      console.log(user);
      let date = Date.now();
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // ...
        console.log("USER IS ALREADY LOGGED IN");
      } else {
        const response = await signInAnonymously(auth);
        if (response) {
          const {
            displayName,
            email,
            emailVerified,
            isAnonymous,
            metadata,
            photoURL,
            uid,
          } = response.user;
          this.setUser({
            displayName,
            email,
            emailVerified,
            isAnonymous,
            metadata,
            photoURL,
            uid,
          });
          update(ref(db, "users/" + response.user.uid), {
            lastLogin: date,
            totalLogins: increment(1),
          });
          mixpanel.identify(response.user.uid);
          mixpanel.track("Sign Up");
        } else {
          throw new Error("signup failed");
        }
      }
    },

    async convertToGoogle() {
      // https://stackoverflow.com/questions/44229111/access-youtube-channel-id-once-signed-in-though-firebase-googleauthprovider
      console.log("convert anonymous user to google signin now");
      mixpanel.people.set({ SignupProvider: "startedConvertGoogle" });
      const uploadStore = useUploadStore()
      const response = await linkWithPopup(auth.currentUser, provider);
      if (response) {
        const {
          displayName,
          email,
          emailVerified,
          isAnonymous,
          metadata,
          photoURL,
          uid,
        } = response.user;
        this.setUser({
          displayName,
          email,
          emailVerified,
          isAnonymous,
          metadata,
          photoURL,
          uid,
        });
        let data = {
          id: uid,
          email: email,
          userType: "extendedFree",
        };
        uploadStore.writeDataToDB(
          uid,
          data,
          "users/" + uid + "/auth"
        );
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // console.log("token");
        // console.log(token);
        mixpanel.people.set({ SignupProvider: "Google" });
      } else {
        throw new Error("signup failed");
      }
    },

    async loginGoogle() {
      console.log("login existing user");
      const response = await signInWithPopup(auth, provider);
      if (response) {
        console.log("response");
        console.log(response);
        const {
          displayName,
          email,
          emailVerified,
          isAnonymous,
          metadata,
          photoURL,
          uid,
        } = response.user;
        this.setUser({
          displayName,
          email,
          emailVerified,
          isAnonymous,
          metadata,
          photoURL,
          uid,
        });
      } else {
        throw new Error("login failed");
      }
    },

    async logout() {
      console.log("logout running");
      await signOut(auth);
      this.setUser(null);
      console.log("logout setUser");
    },
    //Mutation to update the user state
    //When we call this mutation, the payload will be user object from firebase auth
    //When the user logs out, we call the mutation and the payload will be null
    setUser(payload) {
      console.log("setUser triggered");
      this.user = payload;
      //Log out the user state
      console.log("setUser triggered2");
    },
  },
  persist: true,
});
