import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Set the configuration for your app
let dbLocation =
  "https://" +
  process.env.VUE_APP_DB_LOCATION +
  ".europe-west1.firebasedatabase.app";
console.log("dbLocation= " + dbLocation);

const config = {
  apiKey: "AIzaSyD01A4KPN_9S4UO3sbeh6LkTCs5c6uLt4Y",
  authDomain: "bleepme-prod.firebaseapp.com",
  databaseURL: dbLocation,
  projectId: "bleepme-prod",
  storageBucket: "bleepme-prod.appspot.com",
  messagingSenderId: "1010209742952",
  appId: "1:1010209742952:web:81af23cf398a62521c5bdd",
  measurementId: "G-DQZS4GYTNC",
};

const firebaseApp = initializeApp(config);
console.log(firebaseApp);

//initialize firebase auth
const auth = getAuth();

//export the auth object
export { auth };
