<template>
  <div>
    <div
      class="portfolio-modal modal fade show"
      id="portfolioModal2"
      tabindex="-1"
      aria-labelledby="portfolioModal2"
      aria-modal="true"
      role="dialog"
      style="display: block; padding-left: 0px"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button
              class="btn btn-primary"
              href="#!"
              data-bs-dismiss="modal"
              @click="$parent.displayPopup = false"
            >
              <svg
                class="svg-inline--fa fa-times fa-w-11 fa-fw"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path></svg
              ><!-- <i class="fas fa-times fa-fw"></i> Font Awesome fontawesome.com -->
            </button>
          </div>
          <div class="modal-body text-center pb-5">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8">
                  <!-- Portfolio Modal - Title-->
                  <h3>Important: Is your YouTube Content family friendly?</h3>

                  <!-- <h2
                    class="
                      portfolio-modal-title
                      text-secondary text-uppercase
                      mb-0
                    "
                  >
                    Tasty Cake
                  </h2> -->

                  <!-- <div class="divider-custom">
                    <div class="divider-custom-line"></div>
                    <div class="divider-custom-icon">
                      <svg
                        class="svg-inline--fa fa-star fa-w-18"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="star"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                        ></path></svg
                      >
                    </div>
                    <div class="divider-custom-line"></div>
                  </div> -->
                  <!-- Portfolio Modal - Image-->

                  <!-- Portfolio Modal - Text-->

                  <p class="mb-4" v-if="dataProps.youtubeAccountName != null">
                    We are now checking your YouTube Account
                    <img
                      class="avatarBoard"
                      v-if="dataProps.youtubeAccountThumbnail != null"
                      :src="dataProps.youtubeAccountThumbnail"
                    />
                    <b> {{ dataProps.youtubeAccountName }}</b
                    >. You will get a message to your mail adress which is
                    connected to your YouTube Account once the analysis is
                    completed. In seldome cases this takes up to 72 hours.
                  </p>
                  <p class="mb-4" v-if="dataProps.youtubeAccountName === null">
                    Are all of your YouTube Videos

                    <span
                      id="disabled-wrapper"
                      class="d-inline-block"
                      tabindex="0"
                    >
                      <u> family friendly</u>
                    </span>
                    <b-tooltip target="disabled-wrapper"
                      >The 'family friendly' attribute plays a role on how well
                      your videos perform in terms of reach and
                      revenue.</b-tooltip
                    >

                    ? Login with your Youtube account to learn which of your
                    videos are family friendly and what Content you can
                    optimize.
                  </p>
                  <button
                    v-if="dataProps.youtubeAccountName === null"
                    @click="$parent.startAnalyize('YoutubeChannelAnalysisV1')"
                    class="login-with-google-btn"
                  >
                    Sign in with Google
                  </button>
                  <h6 v-if="dataProps.errorAuth === true">
                    {{ dataProps.errorMessage }}
                  </h6>
                  <p class="note">
                    By continuing you agree to bleepme.io
                    <a
                      class="noteLink"
                      href="https://blog.bleepme.io/tos/"
                      rel="nofollow"
                      target="_blank"
                      >Terms of Service</a
                    >,
                    <a
                      class="noteLink"
                      href="https://blog.bleepme.io/privacy/"
                      rel="nofollow"
                      target="_blank"
                      >Privacy Policy and Cookie use</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>
<script>
export default {
  props: {
    dataProps: {
      googleToken: String,
      youtubeAccountName: String,
      youtubeAccountDescription: String,
      youtubeAccountpublishedAt: String,
      youtubeAccountThumbnail: String,
      youtubeAccountCountry: String,
      youtubeAccountId: String,
      youtubeAccountUploadPlaylistId: String,
      youtubeAccountLatestVideos: String,
      errorMessage: String,
      errorAuth: Boolean,
    },
  },
  data() {
    return {};
  },
};
</script>
<style>
.note {
  color: rgba(152, 152, 152, 1);
  font-size: 0.8rem;
  margin-top: 2rem;
}
.noteLink {
  color: #2c3e50;
}
.btn-primary:hover {
  background-color: #3e64ff;
  border-color: #3e64ff;
}
.modal-body.text-center.pb-5 {
  padding-top: 0rem;
}
.avatarBoard {
  border-radius: 50%;
  max-width: 30px;
}
.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}
</style>
