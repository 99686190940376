<template>
  <div class="home">
    <div class="hello">
      <PopUp v-if="displayPopup === true" :dataProps="googleData" />
      <PopUpPremium v-if="displayPopupPremium === true" :dataProps="googleData" />
      <!-- audio recorder -->
      <!-- <vue-record-audio @result="onResult" mode="press"/> -->
      <b-jumbotron bg-variant="white" text-variant="black" border-variant="none">
        <!-- <template #header>What do you want to bleep?</template> -->
        <h1>What do you want to bleep?</h1>

        <p>Choose between beeping any existing video that you own or a new audio file that you can record directly from
          the browser. </p>

        <hr class="my-4">

        <b-container class="bv-example-row">
          <b-row>

            <b-col> <b-link to="upload-video"><b-icon icon="file-earmark-play-fill"
                  style="width: 120px; height: 120px; color: #3e64ff;"></b-icon><br><b-button size="lg"
                  variant="primary">bleep existing Video</b-button></b-link></b-col>
            <b-col><b-link to="upload-audio"><b-icon icon="mic"
                  style="width: 120px; height: 120px; color: #3e64ff;"></b-icon><br><b-button size="lg"
                  variant="outline-primary">bleep new Audio</b-button></b-link></b-col>
          </b-row>

        </b-container>
        <!-- <b-link to="upload-video"><b-button variant="primary">bleep existing Video</b-button></b-link>
    <b-link to="upload-audio"><b-button variant="success">bleep new Audio</b-button></b-link> -->
      </b-jumbotron>



      <div v-if="store.user == null || store.user.isAnonymous !== false">
        <!-- Icon Divider -->
        <div class="divider-custom divider-light">
          <div class="divider-custom-line"></div>
          <div class="divider-custom-icon">
            <i class="fas fa-star"></i>
          </div>
          <div class="divider-custom-line"></div>
        </div>
        <!-- Masthead Subheading -->
        <p class="masthead-subheading font-weight-light mb-0">
          BLEEP - YOUR - VIDEOS
        </p>
        <p>Should we check your YouTube Videos for you?</p>
        <button type="button" value="Upload" @click="checkYoutubeDirectly()" class="btntwo">
          Check my YouTube Content
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PopUp from "@/components/PopUp.vue";
import PopUpPremium from "@/components/PopUpPremium.vue";
import {
  writeDataToDB,
  writeAttemptToFirebase,
  // writeDataToDB,
} from "/firebase";
import { useUserStore } from "@/store/user";
// import { ref } from "@vue/composition-api";
import { ref } from "vue";
import { useRouter } from "vue2-helpers/vue-router";

export default {
  setup() {
    const error = ref(null);
    const displayPopupPremium = ref(false);
    const displayPopup = ref(false);

    const store = useUserStore();
    const router = useRouter();

    const startAnalyize = async (typeOfAttempt) => {
      await handleConvertToGoogle();

      writeAttemptToFirebase(store.user.uid, typeOfAttempt);
    };

    const handleLoginGoogle = async () => {
      try {
        console.log("handleLoginGoogle");
        await store.loginGoogle();
        console.log("handleLoginGoogle done");
      } catch (err) {
        console.log("handleLoginGoogle ERROR");
        error.value = err.message;
        console.log(err);
      }
    };

    const handleLoginAnony = async () => {
      try {
        console.log("anonymSignup");
        await store.anonymSignup();
        console.log("anonymSignup done");
      } catch (err) {
        console.log("anonymSignup ERROR");
        error.value = err.message;
        console.log(err);
      }
    };

    const handleConvertToGoogle = async () => {
      try {
        console.log("convertToGoogle");
        await store.convertToGoogle();
        console.log("convertToGoogle done");
        // close module
        displayPopupPremium.value = false;
        //this.getYoutubeAccountInfo();
      } catch (err) {
        console.log("convertToGoogle ERROR");
        error.value = err.message;
        console.log(err);
        console.log(err.message);
        router.push("/login");
      }
    };

    const checkYoutubeDirectly = async () => {
      displayPopup.value = true;
      await handleLoginAnony();
      console.log("store.user.uid = " + store.user.uid);
      writeAttemptToFirebase(
        store.user.uid,
        "YoutubeChannelAnalysisDirectlyDisplayedV1"
      );
    };


    const setdisplayPopupPremium = () => {
      displayPopupPremium.value = true;
    };

    const callback = (data) => {
      console.debug(data)
      if (data === "pause recording") {
        console.log("pause recording here - now upload")
      }
    }

    const headers = {

    }

    const onResult = (data) => {
      console.log('The blob data:', data);
      console.log('Downloadable audio', window.URL.createObjectURL(data));
    }

    return {
      // you can return the whole store instance to use it in the template
      store,
      handleLoginGoogle,
      handleLoginAnony,
      handleConvertToGoogle,
      error,
      displayPopupPremium,
      setdisplayPopupPremium,
      displayPopup,
      checkYoutubeDirectly,
      startAnalyize,
      callback,
      headers,
      onResult
    };
  },
  data() {
    return {
      googleData: {
        googleToken: null,
        youtubeAccountName: null,
        youtubeAccountDescription: null,
        youtubeAccountpublishedAt: null,
        youtubeAccountThumbnail: null,
        youtubeAccountCountry: null,
        youtubeAccountId: null,
        youtubeAccountUploadPlaylistId: null,
        youtubeAccountLatestVideos: null,
        email: null,
        errorMessage: null,
        errorAuth: false,
      },
    };
  },
  name: "Home",
  components: {
    PopUp,
    PopUpPremium,
  },
};
</script>
